import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { ExperimentsConsts } from '../../../consts/experiments';

enum QueryParamName {
  SESSION_ID = 'bookings_sessionId',
  SERVICE_ID = 'bookings_serviceId',
  RESOURCE_ID = 'bookings_resourceId',
  LOCATION_ID = 'bookings_locationId',
  START_DATE = 'bookings_startDate',
  END_DATE = 'bookings_endDate',
  TIMEZONE = 'bookings_timezone',
  DYNAMIC_PRICE_INFO = 'bookings_dynamicPriceInfo',
  APP_SECTION_PARAMS = 'appSectionParams',
}

type QueryParams = { [paramKey: string]: string };

export const getURLAdapter = ({ flowAPI }: { flowAPI: ControllerFlowAPI }) => {
  const {
    controllerConfig: {
      wixCodeApi: { location },
    },
  } = flowAPI;

  const appSectionParams = getQueryValueByName(
    location.query,
    QueryParamName.APP_SECTION_PARAMS,
  );

  const isAppSectionParamsAdapterEnabled = flowAPI.experiments.enabled(
    ExperimentsConsts.AppSectionParamsAdapter,
  );

  const query = {
    ...(isAppSectionParamsAdapterEnabled
      ? getParsedAppSectionParams(appSectionParams)
      : {}),
    ...location.query,
  };

  const URLAdapter = {
    getServiceId() {
      return getQueryValueByName(query, QueryParamName.SERVICE_ID);
    },
    getSessionId() {
      return getQueryValueByName(query, QueryParamName.SESSION_ID);
    },
    getResourceId() {
      return getQueryValueByName(query, QueryParamName.RESOURCE_ID);
    },
    getLocationId() {
      return getQueryValueByName(query, QueryParamName.LOCATION_ID);
    },
    getStartDate() {
      return getQueryValueByName(query, QueryParamName.START_DATE);
    },
    getEndDate() {
      return getQueryValueByName(query, QueryParamName.END_DATE);
    },
    getTimezone() {
      return getQueryValueByName(query, QueryParamName.TIMEZONE);
    },
    getDynamicPricePreSelection(): DynamicPriceInfo | undefined {
      const dynamicPriceInfo = getQueryValueByName(
        query,
        QueryParamName.DYNAMIC_PRICE_INFO,
      );

      if (!dynamicPriceInfo) {
        return undefined;
      }

      return JSON.parse(dynamicPriceInfo);
    },
  };

  return URLAdapter;
};

export const getQueryValueByName = (
  query: QueryParams,
  queryParamName: QueryParamName,
): string | undefined => {
  const queryParamValue = query[queryParamName];
  return queryParamValue;
};

export const filterQueryParams = (query: QueryParams): QueryParams => {
  const entries = Object.entries(query);
  const filteredEntries = entries.filter(([key]: string[]) =>
    Object.values(QueryParamName).includes(key as QueryParamName),
  );
  return Object.fromEntries(filteredEntries);
};

export const getParsedAppSectionParams = (
  appSectionParams?: string,
): QueryParams => {
  try {
    return JSON.parse(appSectionParams!);
  } catch (e) {
    return {};
  }
};
