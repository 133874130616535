import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import {
  createInitialState,
  FormState,
} from '../../utils/state/initialStateFactory';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  createFormContext,
  FormContext,
} from '../../utils/context/contextFactory';
import { createFormActions, FormControllerActions } from './Actions/actions';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { FormApi } from '../../api/FormApi';
import { mapConfigToState } from '../../utils/dummies/editor-behaviour';
import { createFormBiLogger } from '../../utils/bi/biLoggerFactory';
import { FormStatus } from '../../types/form-state';

const createController: CreateControllerFn = async ({
  flowAPI: flowApi,
}: ControllerParams) => {
  let rerender: (
    propsToUpdate: Partial<FormState | { actions: FormControllerActions }>,
  ) => void = () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        reportError,
        httpClient,
        experiments,
      } = flowApi;
      const bookingsDataCapsule = await BookingsDataCapsule.getInstance(
        flowApi as any,
      );
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig, experiments);
      const formApi = new FormApi({
        httpClient,
        bookingsDataCapsule,
        wixSdkAdapter,
        reportError,
        experiments,
      });
      const initialState: FormState = await createInitialState({
        wixSdkAdapter,
        bookingsDataCapsule,
        flowApi,
        formApi,
        t,
      });

      const shouldInitializeBiLogger =
        flowApi?.bi! && isFormInValidState(initialState);
      const biLogger = shouldInitializeBiLogger
        ? createFormBiLogger({
            viewerBiLogger: flowApi?.bi!,
            formState: initialState,
            wixSdkAdapter,
          })
        : undefined;

      const user = controllerConfig.wixCodeApi.user.currentUser;
      const formContext: FormContext = createFormContext({
        t,
        flowApi,
        wixSdkAdapter,
        bookingsDataCapsule,
        formApi,
        biLogger,
        reportError,
        user,
      });

      const { render, actions, onStateChange } =
        await createControlledComponent<
          FormState,
          FormControllerActions,
          FormContext
        >({
          controllerConfig,
          initialState,
          actionsFactory: createFormActions,
          context: formContext,
        });
      rerender = render;

      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger?.update(state);
        });
      }

      wixSdkAdapter.onUserLogin(actions.onLogin);
    },
    updateConfig($w, config) {
      const stateUpdate = mapConfigToState(config);
      rerender(stateUpdate);
    },
  };
};

function isFormInValidState(formState: FormState) {
  return formState.status && formState.status !== FormStatus.SSR;
}

export default createController;
