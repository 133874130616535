import { PageAPIData } from '../pageApiAdapter';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isInputValid } from './validateURLInput';
import { getURLAdapter } from './URLAdapter';
import { createWixSdkAdapter } from '../../../utils/sdkAdapterFactory';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { FormApi } from '../../FormApi';
import { Location } from '@wix/ambassador-availability-calendar/types';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { resolveSessionStorageData } from '../SessionStorageAdapter/SessionStorageResolver';
import { getInputServiceType } from './utils';
import moment from 'moment-timezone';

export const resolveURLData = async ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<PageAPIData | undefined> => {
  const urlAdapter = getURLAdapter({ flowAPI });

  const isValid = isInputValid({ flowAPI });

  if (!isValid) {
    // URL API is active, but there is no data. We will try to resolve the session storage instead
    return resolveSessionStorageData({
      flowAPI,
    });
  }

  const serviceType = getInputServiceType({ flowAPI });

  const sessionId = urlAdapter.getSessionId();
  const serviceId = urlAdapter.getServiceId();
  const resourceId = urlAdapter.getResourceId();
  const locationId = urlAdapter.getLocationId();
  const startDate = urlAdapter.getStartDate();
  const endDate = urlAdapter.getEndDate();
  const timezone = urlAdapter.getTimezone()!;
  const dynamicPricePreSelection = urlAdapter.getDynamicPricePreSelection();

  if (serviceType === 'class') {
    const sessionData = await resolveBySessionId({
      flowAPI,
      sessionId: sessionId!,
      dynamicPricePreSelection,
      timezone,
    });

    return sessionData;
  }

  if (serviceType === 'appointment') {
    const appointmentData = await resolveForAppointment({
      flowAPI,
      serviceId: serviceId!,
      resourceId: resourceId!,
      locationId: locationId!,
      startDate: startDate!,
      endDate: endDate!,
      timezone,
      dynamicPricePreSelection,
    });

    return appointmentData;
  }

  if (serviceType === 'course') {
    const courseData = await resolveCourseData({
      flowAPI,
      serviceId: serviceId!,
      timezone,
      dynamicPricePreSelection,
    });

    return courseData;
  }

  throw new Error('ERROR: failed to infer URL data service type');
};

export const resolveCourseData = async ({
  flowAPI,
  serviceId,
  timezone,
  dynamicPricePreSelection,
}: {
  flowAPI: ControllerFlowAPI;
  serviceId: string;
  timezone: string;
  dynamicPricePreSelection?: DynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  const wixSdkAdapter = createWixSdkAdapter(
    flowAPI.controllerConfig,
    flowAPI.experiments,
  );
  const bookingsDataCapsule = await BookingsDataCapsule.getInstance(
    flowAPI as any,
  );
  const formApi = new FormApi({
    httpClient: flowAPI.httpClient,
    wixSdkAdapter,
    bookingsDataCapsule,
    reportError: flowAPI.reportError,
    experiments: flowAPI.experiments,
  });

  const isEcom = await formApi.isBookingsOnEcom({
    onError: (error) => {
      throw error;
    },
  });

  return {
    serviceId,
    timezone,
    isEcom,
    dynamicPricePreSelection,
  };
};

export const resolveForAppointment = async ({
  flowAPI,
  serviceId,
  resourceId,
  locationId,
  startDate,
  endDate,
  timezone,
  dynamicPricePreSelection,
}: {
  flowAPI: ControllerFlowAPI;
  serviceId: string;
  resourceId: string;
  locationId: string;
  startDate: string;
  endDate: string;
  timezone: string;
  dynamicPricePreSelection?: DynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  const wixSdkAdapter = createWixSdkAdapter(
    flowAPI.controllerConfig,
    flowAPI.experiments,
  );
  const bookingsDataCapsule = await BookingsDataCapsule.getInstance(
    flowAPI as any,
  );
  const formApi = new FormApi({
    httpClient: flowAPI.httpClient,
    wixSdkAdapter,
    bookingsDataCapsule,
    reportError: flowAPI.reportError,
    experiments: flowAPI.experiments,
  });

  // checking availability with the beginning of day as start date to overcome a potential problem with slot availability, in case selected slot was moved due to selection of other slot
  const beginningOfStartDate = moment(startDate)
    .tz(timezone)
    .startOf('day')
    .toISOString();

  const [availability, isEcom] = await Promise.all([
    formApi.getAppoitmentAvailability({
      serviceId,
      resourceId,
      locationId,
      startDate,
      endDate,
      timezone,
      beginningOfStartDate,
    }),
    formApi.isBookingsOnEcom({
      onError: (error) => {
        throw error;
      },
    }),
  ]);

  return {
    serviceId,
    timezone,
    dynamicPricePreSelection,
    isEcom,
    slotAvailability: availability,
  };
};

export const resolveBySessionId = async ({
  flowAPI,
  sessionId,
  timezone,
  dynamicPricePreSelection,
}: {
  flowAPI: ControllerFlowAPI;
  sessionId: string;
  timezone: string;
  dynamicPricePreSelection?: DynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  const wixSdkAdapter = createWixSdkAdapter(
    flowAPI.controllerConfig,
    flowAPI.experiments,
  );
  const bookingsDataCapsule = await BookingsDataCapsule.getInstance(
    flowAPI as any,
  );
  const formApi = new FormApi({
    httpClient: flowAPI.httpClient,
    wixSdkAdapter,
    bookingsDataCapsule,
    reportError: flowAPI.reportError,
    experiments: flowAPI.experiments,
  });

  const [session, isEcom] = await Promise.all([
    formApi.getSessionById({ sessionId }),
    formApi.isBookingsOnEcom({
      onError: (error) => {
        throw error;
      },
    }),
  ]);

  if (!session) {
    throw new Error(
      `ERROR: unable to resolve URL data, failed to fetch session for id ${sessionId}`,
    );
  }

  const pageData: PageAPIData = {
    timezone,
    serviceId: session.scheduleOwnerId!,
    slotAvailability: {
      openSpots: Math.max(
        session.capacity! - session.totalNumberOfParticipants!,
        0,
      ),
      slot: {
        serviceId: session.scheduleOwnerId!,
        scheduleId: session.scheduleId,
        startDate: session.start?.timestamp?.toISOString(),
        endDate: session.end?.timestamp?.toISOString(),
        timezone,
        location: session.location as Location,
        resource: {
          id: session.affectedSchedules?.[0].scheduleOwnerId,
          name: session.affectedSchedules?.[0].scheduleOwnerName as string,
          scheduleId: session.affectedSchedules?.[0].scheduleId,
        },
      },
    },
    dynamicPricePreSelection,
    isEcom,
  };

  return pageData;
};

export const convertUTCDateStringToDateAdjustedForTimezone = ({
  dateString,
  timezone,
}: {
  dateString?: string;
  timezone?: string;
}) => {
  if (!dateString) {
    return undefined;
  }
  if (!timezone) {
    return new Date(dateString);
  }
  new Date(
    new Date(dateString).toLocaleString('en-US', { timeZone: timezone }),
  );
};
