import {
  ChannelType,
  CreateCheckoutFromCurrentCartRequest,
  CreateCheckoutResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  CreateOrderRequest,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/types';
import { CartFlow, ReservedPaymentOptionIds } from '../../../../types/types';
import { CreateActionParams } from '../actions';
import { bookingsUouSessionAddedToCartModalInteractions } from '@wix/bi-logger-wixboost-ugc/v2';
import { CartModalInteractionActionName } from '../../../../types/biLoggerTypes';

export type OnCartCTAClick = (cartFlow: CartFlow) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (cartFlow: CartFlow) => {
    const { wixSdkAdapter, formApi, biLogger } = context;
    const [state] = getControllerState();
    const { paymentDetails, selectedPaymentOptionId, cartModal, memberships } =
      state;

    if (
      cartFlow === CartFlow.ADD_MORE_SESSIONS ||
      cartFlow === CartFlow.CLOSE_MODAL
    ) {
      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName:
            cartFlow === CartFlow.ADD_MORE_SESSIONS
              ? CartModalInteractionActionName.AddMoreSessions
              : CartModalInteractionActionName.Close,
        }),
      );

      await wixSdkAdapter.navigateAfterAddToCart();
    } else if (cartFlow === CartFlow.CHECKOUT) {
      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName: CartModalInteractionActionName.GoToCheckout,
        }),
      );
      const isSingleItemOnCart = cartModal?.lineItems?.length === 1;
      const isMembershipOptionSelected = memberships?.eligibleMemberships?.find(
        (membership) => selectedPaymentOptionId === membership.id,
      );
      const isCustomPrice =
        paymentDetails.priceText &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const isFreePrice =
        paymentDetails.isFree &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const shouldGoToTYP =
        isMembershipOptionSelected || isCustomPrice || isFreePrice;
      if (isSingleItemOnCart) {
        try {
          const createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest =
            {
              channelType: ChannelType.WEB,
            };
          const checkoutFromCartResponse: CreateCheckoutResponse =
            await formApi.createCheckoutFromCurrentCart(
              createCheckoutFromCurrentCartRequest,
            );
          if (!checkoutFromCartResponse.checkoutId || !shouldGoToTYP) {
            await wixSdkAdapter.navigateToEcomCart();
          }
          if (shouldGoToTYP) {
            const createOrderRequest: CreateOrderRequest = {
              id: checkoutFromCartResponse.checkoutId,
            };
            const orderFromCheckoutResponse: CreateOrderResponse =
              await formApi.createOrderFromCheckout(createOrderRequest);
            if (orderFromCheckoutResponse.orderId) {
              await wixSdkAdapter.navigateToEcomThankYouPage({
                orderId: orderFromCheckoutResponse.orderId,
              });
            }
          }
        } catch (e) {
          await wixSdkAdapter.navigateAfterAddToCart();
        }
      } else {
        await wixSdkAdapter.navigateToEcomCart();
      }
    } else {
      throw new Error('CartFlow cannot be undefined after adding to cart');
    }
  };
}
