import { BookedSlot } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { ServiceType } from '@wix/bookings-uou-types';

type TimeSegment = { startDate: Date; endDate: Date };

const mapSlotToTimeSegment = (slot: BookedSlot): TimeSegment => ({
  startDate: new Date(slot.startDate!),
  endDate: new Date(slot.endDate!),
});

export const isOverlapping = (
  currentSlot: BookedSlot,
  bookingsLineItemOptions: BookingsLineItemOption[],
): boolean => {
  const sameStaffBookingsTimeSegments = bookingsLineItemOptions
    .filter(
      (itemOption) =>
        itemOption.slot?.resource?.id &&
        itemOption.slot?.resource?.id === currentSlot?.resource?.id &&
        itemOption.tags?.includes(ServiceType.INDIVIDUAL),
    )
    .map((itemOption) => mapSlotToTimeSegment(itemOption.slot!));

  sameStaffBookingsTimeSegments.push(mapSlotToTimeSegment(currentSlot));

  sameStaffBookingsTimeSegments.sort(
    (a: TimeSegment, b: TimeSegment) =>
      a.startDate.getTime() - b.startDate.getTime(),
  );

  for (let i = 0; i < sameStaffBookingsTimeSegments.length - 1; i++) {
    if (
      sameStaffBookingsTimeSegments[i].endDate.getTime() >
      sameStaffBookingsTimeSegments[i + 1].startDate.getTime()
    ) {
      return true;
    }
  }
  return false;
};
